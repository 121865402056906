import {useEffect} from "react";
import * as Sentry from "@sentry/react";
import {message} from "antd";
import {useLocation, Location} from "react-router-dom";
import jwt_decode from "jwt-decode";
import bridge from "@vkontakte/vk-bridge";
import {api} from "../../core/api";
import {AuthAppStateType, AuthTypes, VKConnectionStatus} from "../../core/types/authTypes";
import {clientId} from "../../core/constants/googleAuth";
import {setIsAppLoading} from "../../core/helpers/setIsAppLoading";


const loginTGGame = (
    setAuthUser: (a: AuthAppStateType) => void,
    location: Location
) => new Promise(async (resolve) => {
    const pathWithoutId = location.pathname.split('#')[0]
    const pathWithoutRoute = pathWithoutId.split('/tgGame/')[1]
    const [userId, messageId] = pathWithoutRoute.split('/')

    try {
        const res = await api.login({
            user: {
                id: userId,
            }, authType: AuthTypes.TG
        })
        setAuthUser({
            userId: res.userId,
            first_name: res.first_name,
            last_name: res.last_name,
            isAuth: true,
            authType: AuthTypes.TG,
            startGameId: messageId
        })

        localStorage.setItem('tokenBearer', res.bearer)
    } catch (err) {
        console.error('tg error auth')
    }
    resolve(true)
})

const loginVKGame = (
    setAuthUser: (a: AuthAppStateType) => void,
    setIsVisibleHeader: (v: boolean) => void
) => new Promise(async (resolve) => {
    try {
        setIsVisibleHeader(false)
        await bridge.send("VKWebAppInit", {});

        const urlSearchParams = new URLSearchParams(window.location.search);

        const params = Object.fromEntries(urlSearchParams.entries());
        const userId = +params.user_id || +params.viewer_id

        const user = await bridge.send('VKWebAppGetUserInfo', {
            user_id: +userId
        })

        const resLogin = await api.login({
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            nick_name: `${user.first_name} ${user.last_name}`,
            authType: AuthTypes.VKGame,
        })
        setAuthUser({
            userId: resLogin.userId,
            first_name: resLogin.first_name,
            last_name: resLogin.last_name,
            isAuth: true,
            authType: AuthTypes.VKGame,
            bearer: resLogin.bearer,
            avatar: user?.photo_100,
        })
    } catch (err) {
        console.error(err)
        Sentry.captureException(err);
    }
    resolve(true)
})

const loginVK = (setAuthUser: (a: AuthAppStateType) => void) => new Promise((resolve) => {
    // @ts-ignore
    window.VK.Auth.getLoginStatus(async (data: any) => {
        if (data?.status && data.status === VKConnectionStatus.connected) {
            try {
                const res = await api.login({...data.session, authType: AuthTypes.VK})
                setAuthUser({
                    userId: res.userId,
                    first_name: res.first_name,
                    last_name: res.last_name,
                    isAuth: true,
                    authType: AuthTypes.VK
                })
                localStorage.setItem('tokenBearer', res.bearer)
                resolve(true)
            } catch (err) {
                resolve(null)
            }
        } else {
            resolve(null)
        }
    })
})

const initializeGoogleApp = (setAuthUser: (a: AuthAppStateType) => void) => new Promise((resolve) => {
    // @ts-ignore
    window.google.accounts.id.initialize({
        client_id: clientId,
        auto_select: true,
        scope: "email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
        callback: async (tokenResponse: any) => {
            if (tokenResponse.credential) {
                let payload: any = jwt_decode(tokenResponse.credential);
                const res = await api.login({
                    user: {
                        id: payload.sub,
                        domain: payload.name,
                        href: payload.picture,
                        first_name: payload.given_name,
                        last_name: payload.family_name,
                        nickname: payload.email
                    }, authType: AuthTypes.Google, expire: payload.exp
                })
                setAuthUser({
                    userId: res.userId,
                    first_name: res.first_name,
                    last_name: res.last_name,
                    isAuth: true,
                    authType: AuthTypes.Google
                })
                localStorage.setItem('tokenBearer', res.bearer)
            }
            resolve(tokenResponse)
        },
    })
    setTimeout(() => {
        resolve(false)
    }, 1000)
})

const getGoogleClientAPI = () => new Promise((resolve) => {
    const script = document.createElement('script');
    script.onload = () => {
        resolve(true)
    }
    script.onerror = console.error;
    script.async = true;
    script.src = 'https://accounts.google.com/gsi/client';
    document.head.appendChild(script);
})

export const loginGoogle = (setAuthUser: (a: AuthAppStateType) => void) => new Promise(async (resolve) => {
    try {
        await getGoogleClientAPI()
        await initializeGoogleApp(setAuthUser)

        // @ts-ignore
        google.accounts.id.prompt(async (notification) => {
            if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                // continue with another identity provider.
            } else {

            }
        });
    } catch (err) {
        console.log(err)
    }

    // @ts-ignore
    resolve(true)
})

export const getTGGameApi = () => {
    const script = document.createElement('script');

    script.src = "https://telegram.org/js/games.js";
    script.async = true;

    document.body.appendChild(script);
}

export const getVKAPI = () => new Promise((resolve) => {
    const script = document.createElement('script');
    script.onload = () => {
        // @ts-ignore
        if (!window.VK?._apiId) {
            // @ts-ignore
            window.VK.init({apiId: '51417025'})
        }
        resolve(true)
    }
    script.onerror = console.error;
    script.async = true;
    script.src = 'https://vk.com/js/api/openapi.js?169';
    document.head.appendChild(script);
})

export const useAuth = (
    setAuthUser: (a: AuthAppStateType) => void,
    setIsVisibleHeader: (v: boolean) => void,
    setIsLoadedVKApi: (v: boolean) => void,
    setIsLoadedGoogleApi: (v: boolean) => void,
) => {
    const location = useLocation()

    useEffect(() => {
        (async function () {
            try {
                // message.open({
                //     type: 'info',
                //     content: 'Спасибо что продолжаете играть, это радует. Удалось реализовать стабильную работу сервера, и теперь плотнее займусь пользовательским интерфейсом. Уже добавил несколько новых фич)',
                //     duration: 3
                // });
                if (location.pathname.includes('/tgGame/')) {
                    getTGGameApi()
                    await loginTGGame(setAuthUser, location)
                } else if (location.pathname.includes('/vkGame')) {
                    await loginVKGame(setAuthUser, setIsVisibleHeader)
                } else {
                    const bearerToken = localStorage.getItem('tokenBearer')
                    if (bearerToken) {
                        const userData: any = jwt_decode(bearerToken)
                        const userAuthType = userData.userId.split('_')[0]
                        if (userAuthType.toUpperCase() === AuthTypes.VK) {
                            await getVKAPI()
                            setIsLoadedVKApi(true)
                            await loginVK(setAuthUser)
                        } else if (userAuthType.toUpperCase() === AuthTypes.Google.toUpperCase()) {
                            await loginGoogle(setAuthUser)
                            setIsLoadedGoogleApi(true)
                        }
                    }
                    setIsAppLoading()
                }
            } catch (err) {
                Sentry.captureException(err);
                // @ts-ignore
                message.error(err.toString());
                setIsAppLoading()
            }
        })()
    }, [])

}

export const useYaMetric = () => {
    setTimeout(() => {
        // @ts-ignore
        window["ym"] = window["ym"] || function () {
            // @ts-ignore
            (window["ym"].a = window["ym"].a || []).push(arguments)
        };
        // @ts-ignore
        window["ym"].l = 1 * new Date();
        for (var j = 0; j < document.scripts.length; j++) {
            if (document.scripts[j].src === "https://mc.yandex.ru/metrika/tag.js") {
                return;
            }
        }
        let k = document.createElement("script")
        let a = document.getElementsByTagName("script")[0]
        // @ts-ignore
        k.async = 1
        k.src = "https://mc.yandex.ru/metrika/tag.js"
        // @ts-ignore
        a.parentNode.insertBefore(k, a)

        // @ts-ignore
        window.ym(91734980, "init", {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true
        });
    }, 0)
}

export const useGoAnalytic = () => {
    setTimeout(() => {
        const script = document.createElement('script');

        script.src = "https://www.googletagmanager.com/gtag/js?id=G-22SRD6TD6C";
        script.async = true;

        document.body.appendChild(script);

        // @ts-ignore
        window.dataLayer = window.dataLayer || [];

        function gtag() { // @ts-ignore
            window.dataLayer.push(arguments);
        }

        // @ts-ignore
        gtag('js', new Date());

        // @ts-ignore
        gtag('config', 'G-22SRD6TD6C');
    }, 0)
}
