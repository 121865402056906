import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {AppContainer} from "./components/AppContainer";
import * as Sentry from "@sentry/react";
import {useGoAnalytic as startGoAnalytic, useYaMetric as startYaMetric} from "./components/AppContainer/hooks";
import 'antd/dist/reset.css';

// эта логика подключения вынесена из основного потока, что бы google perfomans не ругался
let isActiveAnalytics = false

const addAnalytics = () => {
    if (!isActiveAnalytics) {
        startGoAnalytic()
        startYaMetric()
        if (process.env.NODE_ENV === "production") {
            Sentry.init({
                dsn: "https://0b95655cfa5a413995a285477beae4dd@o4504385095204864.ingest.sentry.io/4504385096450048",
                replaysSessionSampleRate: 0.1,
                // If the entire session is not sampled, use the below sample rate to sample
                // sessions when an error occurs.
                replaysOnErrorSampleRate: 1.0,
                integrations: [new Sentry.Replay()],
                release: process.env.NODE_ENV === "production" ? 'release 11-01-2023' : 'development',
                // tracesSampleRate: 1.0,
            });
        }
        isActiveAnalytics = true

        setTimeout(() => {
            document.removeEventListener('mousemove', addAnalytics)
            document.removeEventListener('touchstart', addAnalytics)
        }, 100)
    }
}

document.addEventListener('mousemove', addAnalytics)
document.addEventListener('touchstart', addAnalytics)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<BrowserRouter><AppContainer /></BrowserRouter>);
