import React, {MouseEventHandler} from "react";

type BurgerMenuIcon = {
    onClick: MouseEventHandler<SVGSVGElement>
}

export const BurgerMenuIcon: React.FC<BurgerMenuIcon> = ({onClick}) => {
    return <svg onClick={onClick} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path fill="white" d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"/>
    </svg>
}