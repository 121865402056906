export enum AuthTypes {
    VK = 'VK',
    VKGame = 'VKGame',
    NA6 = 'NA6',
    Google = 'Google',
    TG = 'TG',
    anotherTypes = 'anotherTypes'
}

export type AuthAppStateType = {
    userId: string | null,
    first_name: string | null,
    last_name: string | null,
    isAuth: boolean | null,
    authType: AuthTypes | null,
    startGameId?: string | null
    bearer?: string | null
    avatar?: string | null
}

export enum VKConnectionStatus {
    connected = 'connected',
    not_authorized = 'not_authorized',
    unknown = 'unknown'
}

export type SessionVKType = {
    "mid": string,
    "sid": string,
    "sig": string,
    "secret": string,
    "expire": number
}

export type VKAuthGetLoginStatus = {
    session: SessionVKType,
    status: VKConnectionStatus
}

export type VkAuthResponseType = {
    "session": {
        "mid": string,
        "sid": string,
        "sig": string,
        "secret": string,
        "expire": number,
        "user": {
            "id": string,
            "domain": string,
            "href": string,
            "first_name": string,
            "last_name": string,
            "nickname": string
        }
    },
    "status": VKConnectionStatus
}

export type LoginResponseTypes = {
    userId: string,
    first_name: string,
    last_name: string,
    nick_name?: string,
    authType: AuthTypes,
    bearer: string
}
