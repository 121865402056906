import React from "react";
import {Link} from "react-router-dom";
import { Button } from "antd";
import {defaultAuthState} from "../../../core/constants/defaultAuthState";
import {AuthAppStateType, AuthTypes} from "../../../core/types/authTypes";
import styles from './styles.module.css'

type AuthBlockProps = {
    auth: AuthAppStateType,
    setAuthUser: (auth: AuthAppStateType) => void
    setIsLoading: (isLoading: boolean) => void
}

export const AuthBlock: React.FC<AuthBlockProps> = ({auth, setAuthUser, setIsLoading}) => {
    const onLogout = async () => {
        if (auth.authType === AuthTypes.VK) {
            setIsLoading(true)

            // @ts-ignore
            await window.VK.Auth.logout(() => {
                setAuthUser(defaultAuthState)
                localStorage.removeItem('tokenBearer')
                setIsLoading(false)
            })
        }

        if (auth.authType === AuthTypes.Google) {
            setIsLoading(true)
            // @ts-ignore
            window.google.accounts.id.revoke(auth.userId?.split('_')[1], done => {
                setAuthUser(defaultAuthState)
                localStorage.removeItem('tokenBearer')
                setIsLoading(false)
            })
        }
    }
    return <div>
        <Link to={`profile/${auth.userId}`} className={styles.profileLink}>{auth.first_name} {auth.last_name}</Link>
        <Button onClick={onLogout} className={styles.logoutBtn}>Выйти</Button>
    </div>
}
