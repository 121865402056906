import {useEffect, useState} from "react";

export const useWindowsWidth = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        let checkScreenSize = () => {
            setWidth(window.innerWidth);
        };
        checkScreenSize();
        window.addEventListener("resize", checkScreenSize);

        return () => window.removeEventListener("resize", checkScreenSize);
    }, []);

    return width;
};