import React, {useRef, useState} from "react";
import ReactDom from "react-dom";
import {NavLink} from "react-router-dom";
import {BurgerMenuIcon} from "../../../core/assets/icons/BurgerMenuIcon";
import {useOnClickOutside} from "../../../core/helpers/useOutsideClick";
import styles from './styles.module.css'

export const BurgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false)
    const burgerRef = useRef<HTMLDivElement>(null)

    const getLinkClassName = ({ isActive }: {isActive: boolean}) => isActive ? styles.activeBurgerLink : styles.unactiveBurgerLink

    useOnClickOutside(burgerRef, () => {
        burgerRef.current?.classList.add(styles.closeMenu)
        setTimeout(() => {
            setIsOpen(false)
        }, 240)
    })

    const onClickItem = () => {
        burgerRef.current?.classList.add(styles.closeMenu)
        setTimeout(() => {
            setIsOpen(false)
        }, 240)
    }

    return <div className={styles.burgerMenuCont}>
        <BurgerMenuIcon onClick={() => {
            if (!isOpen) {
                setIsOpen(true)
            }
        }}/>
        {isOpen && ReactDom.createPortal(<div ref={burgerRef} className={styles.openedBurger}>
            <NavLink onClick={onClickItem} className={getLinkClassName} to="/">Главная</NavLink>
            <NavLink onClick={onClickItem} className={getLinkClassName} to="/singleGame">Одиночная игра</NavLink>
            <NavLink onClick={onClickItem} className={getLinkClassName} to="/multiplayerGame">Игра с другим игроком</NavLink>
            <NavLink onClick={onClickItem} className={getLinkClassName} to="/simulateGame">Игра ботов</NavLink>
            {/*<NavLink onClick={onClickItem} className={getLinkClassName} to="/usersList">Топ игроков</NavLink>*/}
        </div>, document.body) }
    </div>
}