import axios from "axios"
import {BetsEnum, CellValueEnum} from "../constants/enums";
import {LoginResponseTypes} from "../types/authTypes";
import {UserType} from "../types/userTypes";
import {ReplayListType, ReplayRecordType} from "../types/fieldTypes";

const baseUrl = process.env.NODE_ENV === "production" ? '' : 'http://localhost:4400'

export const wsApi = {

}

export const api = {
    startSingleGame: (userField: CellValueEnum[][], token?:string | null) => axios.post(`${baseUrl}/startSingleGame`, {userField}, {
        headers: {authorization: token || localStorage.getItem('tokenBearer') as string}
    }).then(res => res.data),

    tgWin: (id:string, gameId: string, isMultiplayer: boolean = false) => axios.post(`${baseUrl}/tgWin`, {id, gameId, isMultiplayer}, {
        headers: {authorization: localStorage.getItem('tokenBearer') as string}
    }).then(res => res.data),

    getChatResult: (id:string, gameId: string) => axios.get(`${baseUrl}/getChatResult`, {
        params: {
            id,
            gameId
        }
    }).then(res => res.data),

    strikeCell: (gameId: string, row: number, col: number, token?:string | null) => axios.get(`${baseUrl}/strikeCell`, {
        params: {gameId, row, col},
        headers: {authorization: token || localStorage.getItem('tokenBearer') as string}
    }).then(res => res.data),

    login: (auth: any) => axios.post<LoginResponseTypes>(`${baseUrl}/login`, auth).then(res => res.data),

    getUserInfo: (userId: string, bearerToken?:string) => axios.get<UserType>(`${baseUrl}/getUserInfo`, {
        params: {userId},
        headers: {authorization: bearerToken || localStorage.getItem('tokenBearer') as string}
    }).then(res => res.data),

    getUsersList: (options?: any, bearer?: string | null) => axios.get<UserType[]>(`${baseUrl}/getUsersList`, {
        headers:  {authorization: bearer || localStorage.getItem('tokenBearer') as string},
        params: options
    }).then(res => res.data),

    placeBet: (userId: string, bet: BetsEnum) => axios.get(`${baseUrl}/placeBet`, {
        params: {userId, bet},
        headers: {authorization: localStorage.getItem('tokenBearer') as string}
    }).then(res => res.data),

    resultBet: (userId: string, result: BetsEnum,  betId: string) => axios.get(`${baseUrl}/resultBet`, {
        params: {userId, result, betId},
        headers: {authorization: localStorage.getItem('tokenBearer') as string}
    }).then(res => res.data),

    getReplayRecordsByUserId: (userId: string) => axios.get<ReplayListType>(`${baseUrl}/getRecordForUser`, {
        params: {userId},
    }).then(res => res.data),

    getGameRecord: (recordId: string) => axios.get<ReplayRecordType>(`${baseUrl}/getRecord`, {
        params: {recordId},
    }).then(res => res.data),

    getRecordsCountByDay: (dayTime?: number) => axios.get<number>(`${baseUrl}/getRecordsCountByDay`, {
        params: {dayTime},
    }).then(res => res.data),

    getRecords: (params?: any) => axios.get<{count: number, records: ReplayRecordType[] }>(`${baseUrl}/getRecords`, {
        params
    }).then(res => res.data),
}
