import React, {useEffect} from "react";
import {useParams, useLocation} from "react-router-dom";

export const RedirectMobileVKAuth = () => {
    const params = useLocation()
    useEffect(() => {
        const redirectUri = params.search.replace('?', '').split('=')[1]
        window.location.replace(`${redirectUri}${params.hash.replace('#', '')}`)
    }, [])
    return null
}
