import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import gsap from "gsap";
import {AuthBlock} from "./AuthBlock";
import {Button} from "antd";
import {AuthAppStateType, AuthTypes} from "../../core/types/authTypes";
import {useWindowsWidth} from "../../core/helpers/useWindowWidth";
import {BurgerMenu} from "./BurgerMenu";
import styles from './styles.module.css'
import appStyles from "../AppContainer/styles.module.css";

type NavigateProps = {
    auth: AuthAppStateType,
    setAuthUser: (auth: AuthAppStateType) => void
    setIsLoading: (isLoading: boolean) => void
}

export const Navigate = React.forwardRef<HTMLDivElement, NavigateProps>(({auth, setAuthUser, setIsLoading}, ref) => {
    const getLinkClassName = ({isActive}: { isActive: boolean }) => isActive ? styles.activeLink : styles.unactiveLink

    const width = useWindowsWidth()
    const navigate = useNavigate()

    if (auth?.authType === AuthTypes.TG || auth?.authType === AuthTypes.VKGame) return null

    return <header ref={ref} className="navigatePanel">
        {width > 668
            ? <nav className="linksContainer">
                <NavLink className={getLinkClassName} to="/">Главная</NavLink>
                <NavLink className={getLinkClassName} to="/singleGame">Одиночная игра</NavLink>
                <NavLink className={getLinkClassName} to="/multiplayerGame">Игра с другим игроком</NavLink>
                <NavLink className={getLinkClassName} to="/simulateGame">Игра ботов</NavLink>
                {/*<NavLink className={getLinkClassName} to="/usersList">Топ игроков</NavLink>*/}
            </nav>
            : <BurgerMenu/>
        }
        {auth.isAuth
            ? <AuthBlock auth={auth} setAuthUser={setAuthUser} setIsLoading={setIsLoading}/>
            : <NavLink className={getLinkClassName} to="/login">
                <Button type={"primary"}>Войти</Button>
            </NavLink>}
    </header>
})
