import React, {useRef, useState, Suspense, lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import clsx from "clsx";
import {Navigate as NavigatePanel} from "../Navigate";
import {defaultAuthState} from "../../core/constants/defaultAuthState";
import {AuthAppStateType} from "../../core/types/authTypes";
import {useAuth} from "./hooks";
import styles from './styles.module.css'
import {RedirectMobileVKAuth} from "../RedirectMobileVKAuth";

const HomePage = lazy(() => import('../../pages/HomePage'));
const LoginPage = lazy(() => import('../../pages/LoginPage'));
const SingleGamePage = lazy(() => import('../../pages/SingleGamePage'));
const SimulateGamePage = lazy(() => import('../../pages/SimulateGamePage'));
const MultiplayerGame = lazy(() => import('../../pages/MultiplayerGame'));
const TGGamePage = lazy(() => import('../../pages/TGGamePage'));
const UsersListPage = lazy(() => import('../../pages/UsersListPage'));
const VKGamePage = lazy(() => import('../../pages/VKGamePage'));
const ProfilePage = lazy(() => import('../../pages/ProfilePage'));
const ReplayRecordPage = lazy(() => import('../../pages/ReplayRecordPage'));
const RecordsListPage = lazy(() => import('../../pages/RecordsListPage'));

export const AppContainer = () => {
    const [authUser, setAuthUser] = useState<AuthAppStateType>(defaultAuthState)
    const [isLoadedVKApi, setIsLoadedVKApi] = useState<boolean>(false)
    const [isLoadedGoogleApi, setIsLoadedGoogleApi] = useState<boolean>(false)
    const [isVisibleHeader, setIsVisibleHeader] = useState<boolean>(true)
    const [isLoadingApp, setIsLoadingApp] = useState<boolean>(true)

    const navigateRef = useRef<HTMLDivElement>(null)

    const hideHeader = (value: boolean) => {
        if (!value) {
            navigateRef.current?.classList.add(styles.hideHeader)
            setTimeout(() => {
                setIsVisibleHeader(value)
            }, 500)
        } else {
            setIsVisibleHeader(value)
        }
    }

    useAuth(setAuthUser, setIsVisibleHeader, setIsLoadedVKApi, setIsLoadedGoogleApi)

    return <>
        {isVisibleHeader &&
            <NavigatePanel ref={navigateRef} auth={authUser} setAuthUser={setAuthUser} setIsLoading={setIsLoadingApp}/>}
        <main className={clsx("mainLayout")}>
            <Suspense>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/login"
                           element={<LoginPage
                               setAuthUser={setAuthUser}
                               setIsLoading={setIsLoadingApp}
                               isLoadedGoogleApi={isLoadedGoogleApi}
                               setIsLoadedGoogleApi={setIsLoadedGoogleApi}
                               isLoadedVKApi={isLoadedVKApi}
                               setIsLoadedVKApi={setIsLoadedVKApi}
                           />}/>
                    <Route path="/singleGame"
                           element={<SingleGamePage auth={authUser} setIsVisibleHeader={hideHeader}/>}/>
                    <Route path="/multiplayerGame"
                           element={<MultiplayerGame auth={authUser} setIsVisibleHeader={hideHeader}/>}/>
                    <Route path="/simulateGame" element={<SimulateGamePage auth={authUser}/>}/>
                    <Route path="/tgGame/:userId/:gameId" element={<TGGamePage auth={authUser}/>}/>
                    <Route path="/vkGame/" element={<VKGamePage auth={authUser}/>}/>
                    <Route path="/usersList" element={<UsersListPage/>}/>
                    <Route path="/profile/:userId" element={<ProfilePage/>}/>
                    <Route path="/replayGame/:gameId" element={<ReplayRecordPage/>}/>
                    <Route path="/recordsList" element={<RecordsListPage/>}/>
                    <Route path="/mobileVKAuth" element={<RedirectMobileVKAuth/>}/>
                    <Route
                        path="*"
                        element={<Navigate to="/" replace/>}
                    />
                </Routes>
            </Suspense>
        </main>
    </>
}
